<script>
import { Input } from 'ant-design-vue'
import { mapActions } from 'vuex'
import { timeFix } from '@/utils/util'
import { getAction } from '@/command/netTool'
import { setCookie } from '@/utils/cookie'

export default {
  data() {
    return {
      username: '',
      password: ''
    }
  },
  methods: {
    ...mapActions(['Login', 'Logout']),
    renderFormItem(item) {
      return (
        <div class="login-form-item">
          <span>{item.name}</span>
          <Input v-model={this[item.key]} onPressEnter={this.onLogin} type={item.key} />
        </div>
      )
    },
    loginSuccess(userData) {
      let { user_info, access_token, token_type } = userData
      localStorage.setItem('userInfos', JSON.stringify(user_info))
      setCookie('access_token', `${access_token}`, 5)

      this.$router.push({ path: '/' })
      setTimeout(() => {
        this.$notification.success({
          message: '欢迎',
          description: `${timeFix()}，欢迎回来`
        })
      }, 1000)
      this.isLoginError = false
    },
    onLogin() {
      if (this.username == '' || this.password == '') {
        this.$message.error('请输入账号/密码')
        return
      }
      this.Login({
        username: this.username,
        password: this.password
      })
        .then(result => this.loginSuccess(result))
        .catch(err => {
          getAction('/api/account/lock/queryLockInfo?username=' + this.username).then(res => {
            if (res.code === 200) {
              let { data } = res
              if (data.lockStatus == '0') {
                this.$message.error(`账号密码错误，还有${data.errorTimes}次账号将锁定！`)
              } else if (data.lockStatus == '1') {
                this.$message.error(`账号已锁定，请${data.lockTimes}分钟后充重试！`)
              } else {
                this.$message.error(err.msg)
              }
            } else {
              this.$message.error('登录失败，请重新登入！')
            }
          })
        })
        .finally(() => {
          // state.loginBtn = false
        })
    },
    renderFormButton() {
      return (
        <div class="login-form-button" onClick={this.onLogin}>
          登录
        </div>
      )
    },
    renderForm() {
      const form = [
        {
          name: '账号/用户名',
          key: 'username'
        },
        {
          name: '登录密码',
          key: 'password'
        }
      ]
      return (
        <div class="login-form">
          <div>未来乡村</div>
          <div>用户信息管理系统</div>
          <div class="login-form-view">{form.map(e => this.renderFormItem(e))}</div>
          {this.renderFormButton()}
        </div>
      )
    },
    renderBody() {
      return <div class="login-body">{this.renderForm()}</div>
    }
  },
  render() {
    return <div class="login-main">{this.renderBody()}</div>
  }
}
</script>
<style lang="less" scoped>
.login-main {
  display: flex;
  background-image: url('../../assets/View/login-bk.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.login-body {
  width: 813px;
  height: 440px;
  display: flex;
  background-image: url('../../assets/View/login-body.png');
  background-size: 100% 100%;
  position: relative;
}
.login-form {
  position: absolute;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background-color: white;
  border-radius: 30px;
  width: 329px;
  padding: 53px;
  /deep/.ant-input-affix-wrapper {
    border: none;
    background: #f6f6f6;
    outline: none;
    width: 95%;
    box-shadow: none;
  }

  & > :nth-child(-n + 2) {
    font-size: 27px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    position: relative;
    &:nth-child(2)::after {
      position: absolute;
      content: '';
      left: 0px;
      bottom: -10px;
      width: 59px;
      height: 3px;
      background: #1ec57b;
    }
  }
}
.login-form-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 218px;
  height: 40px;
  background: linear-gradient(0deg, #1ec57b 0%, #26e8ac 100%);
  border-radius: 5px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #ffffff;
  margin-top: 46px;
  cursor: pointer;
}
.login-form-item {
  &:not(&:first-child) {
    margin-top: 35px;
  }
  span {
    font-size: 11px;
    font-family: Source Han Sans CN;
    font-weight: 300;
    color: #999999;
  }
  input {
    border: none;
    border-bottom: 1px solid rgba(51, 51, 51, 0.2);
    outline: none;
    margin-top: 5px;
  }
}
.login-form-view {
  margin-top: 40px;
}
</style>
